import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Button } from 'antd';
import NetUtil from "../../Utils/NetUtil";
import StorageValue from '../../Utils/StorageValue';
import md5 from "md5"

export default class ChangePassView extends React.Component {
    constructor() {
        super();
        this.state = {
            account: StorageValue.userName,
            newPassword: null,
            sureNewPassword: null,
            OldPwd: null,
        }
    }

    setNewPass(value) {
        this.setState({
            newPassword: value
        })
    }

    setOldPass(value) {
        this.setState({
            OldPwd: value
        })
    }

    setAgainPass(value) {
        this.setState({
            sureNewPassword: value
        })
    }


    handleChangePass() {
        if (this.state.newPassword == null) {
            message.error("密码不能为空！");
            return;
        }

        if (this.state.newPassword != this.state.sureNewPassword) {
            message.error("两次输入密码不一致！");
            return;
        }

        let tm = Math.floor(new Date().valueOf()/1000)
        NetUtil.Post(NetUtil.BaseUrl + "user/pwd",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(this.state.OldPwd+this.state.newPassword+tm+StorageValue.userName+StorageValue.Token),
                old: this.state.OldPwd,
                pwd: this.state.newPassword,
            },
            (msg) => {
                let result = msg.result
                if (result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.error("修改成功");
                this.setState({
                    idCard: "",
                    deviceId: ""
                })
            },
            (err) => {
                message.error(err);
                return;
            })
    }

    render() {
        return (
            <div>
                <Form style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "30px" }}>修改密码</h1>
                    <Form.Item>
                        <Input
                            placeholder="玩家账号..."
                            // style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.account}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Input
                            // style={{ width: '50%' }}
                            type="password"
                            placeholder="旧密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.OldPwd}
                            onChange={(e) => this.setOldPass(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            // style={{ width: '50%' }}
                            type="password"
                            placeholder="请输入新密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.newPassword}
                            onChange={(e) => this.setNewPass(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Input
                            // style={{ width: '50%' }}
                            type="password"
                            placeholder="请再次输入新密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.sureNewPassword}
                            onChange={(e) => this.setAgainPass(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" 
                            onClick={() => { this.handleChangePass() }}
                        >
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}