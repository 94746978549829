import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, Modal } from 'antd';
import { subDays } from 'date-fns'
import md5 from "md5"
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    user: string
}

/**
 * 兑换日志
 *Created by liangzai on 21/04/07.
 */
export default class RechargeTotalView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayEnd(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 6));
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            user: ''
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let tm = Math.floor(new Date().valueOf() / 1000)
        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5("" + Math.floor(this.state.begin / 1000) + Math.floor(this.state.end / 1000) + this.state.user + tm + StorageValue.userName + StorageValue.Token),
            begin: Math.floor(this.state.begin / 1000),
            end: Math.floor(this.state.end / 1000),
            game: this.state.user
        };

        NetUtil.Post(NetUtil.BaseUrl + "log/total", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];

                if (msg.list) {
                    let total = Object.create(null)
                    total["date"] = "合计"
                    total["use"] = "all"
                    data.push(total)

                    if(this.state.user != ""){
                        for (let index = 0; index < msg.list.length; index++) {
                            const element = msg.list[index];
                            
                            Object.entries(element).forEach(([k,v]) =>{
                                if( k== "_id" || k == "use" || k == "date"){
                                    return true
                                }
    
                                if( total[k]){
                                    total[k] += v
                                }else{
                                    total[k] = v
                                }
                            })
    
                            data.push(element)
                        }
                    }else{
                        let dates = Object.create(null)

                        for (let index = 0; index < msg.list.length; index++) {
                            const element = msg.list[index];
                            let date = dates[element.date]
                            if(!date){
                                date = Object.create(null)
                                date["date"] = element.date
                                date["use"] = "all"
                                dates[element.date] = date
                            }
                            
                            Object.entries(element).forEach(([k,v]) =>{
                                if( k== "_id" || k == "use" || k == "date" ){
                                    return true
                                }
    
                                if( date[k]){
                                    date[k] += v
                                }else{
                                    date[k] = v
                                }

                                if( total[k]){
                                    total[k] += v
                                }else{
                                    total[k] = v
                                }
                            })
                        }

                        Object.entries(dates).forEach(([k,v]) =>{
                            data.push(v)
                        })
                    }
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            if (a.date == b.date) {
                                return a.use < b.use ? 1 : -1
                            }
                            return a.date < b.date ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //设置游戏类型
    private SetGame(value: string) {
        this.setState({
            user: value
        })
    }

    render() {

        this.columns = [
            {
                align: "center", title: "日期", dataIndex: "date", key: "date", render: (text, record) => {
                    if (text.length == 8) {
                        return <div>{text.slice(0, 4) + '-' + text.slice(4, 6) + '-' + text.slice(6)}</div>
                    }
                    return <div>{text}</div>
                }
            },
            {
                align: "center", title: "游戏", dataIndex: "use", key: "use", render: (text, record) => {
                    if (text == "all") {
                        return <div>{"统计"}</div>
                    }
                    return <div>{StorageValue.getUserName(text)}</div>
                }
            }
        ]

        StorageValue.Channel.map((item: any) => {
            this.columns.push({ align: "center", title: item.value, dataIndex: item.key, key: item.key })
        })

        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 6))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>
                    <Form.Item label='游戏'>
                        <Select
                            style={{ width: 120 }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetGame(value) }}
                        >
                            <Option key={""} value={""}>{"全部-统计"}</Option>
                            <Option key={"all"} value={"all"}>{"全部-详情"}</Option>
                            {
                                StorageValue.User.map((item: any) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                </Form>
                <Divider />
                <Table
                    pagination={{ pageSize: 100 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
} 