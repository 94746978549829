import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, Tabs, Button, Select } from 'antd';
import NetUtil from "../../Utils/NetUtil";
import StorageValue from '../../Utils/StorageValue';
import md5 from "md5"
import EnumUtil from '../../Utils/EnumUtil';
const { Option } = Select;

interface IProps {
    visible: boolean
    onCancel: Function
    onSendApplySuccess: Function
}

interface IState {
    name: string,       // 名字
    type: string,       // 类型
    trade: string,      // 支付方式
    fee: number,        // 税率
    limit: number,      // 每日限额
    ali: {              // 支付宝单独配置
        appId: string
        appPrivate: string
        aliPublic: string
        certPath: string
    },
    dili: {             // 滴沥单独配置
        mchNo: string   // 商户号
        appId: string   // 应用ID
        appKey: string  // 应用私钥匙
        appUrl: string  // 请求地址
    },
    jx: {
        mchNo: string        // 商户号
        appPrivate: string   // 应用私钥
        jxPublic: string     // 平台公钥
        appUrl: string       // 请求地址
    }
}

export default class AddChannelModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.resetState()
    }

    resetState() {
        this.state = {
            name: "",
            type: "alipay",
            trade: EnumUtil.ChannelTradeList()[0].key,
            fee: 0,
            limit: -1,
            ali: {
                appId: "",
                appPrivate: "",
                aliPublic: "",
                certPath: ""
            },
            dili: {
                mchNo: "",
                appId: "",
                appKey: "",
                appUrl: ""
            },
            jx: {
                mchNo: "",
                appPrivate: "",
                jxPublic: "",
                appUrl: ""
            },
        }
    }

    setName(value: string) {
        this.setState({
            name: value
        })
    }

    setType(value: string) {
        this.setState({
            type: value
        })
    }

    setTrade(value: string) {
        this.setState({
            trade: value
        })
    }

    setFee(value: number) {
        this.setState({
            fee: value
        })
    }

    setLimit(value: number) {
        this.setState({
            limit: value
        })
    }

    setAppId(value: string) {
        this.state.ali.appId = value;
        this.setState({
            ali: this.state.ali
        })
    }

    setAliPrivate(value: string) {
        this.state.ali.appPrivate = value;
        this.setState({
            ali: this.state.ali
        })
    }

    setAliPublic(value: string) {
        this.state.ali.aliPublic = value;
        this.setState({
            ali: this.state.ali
        })
    }

    setAliCertPath(value: string) {
        this.state.ali.certPath = value;
        this.setState({
            ali: this.state.ali
        })
    }

    setDiliMchNo(value: string) {
        this.state.dili.mchNo = value;
        this.setState({
            dili: this.state.dili
        })
    }

    setDiliKey(value: string) {
        this.state.dili.appKey = value;
        this.setState({
            dili: this.state.dili
        })
    }

    setDiliAppId(value: string) {
        this.state.dili.appId = value;
        this.setState({
            dili: this.state.dili
        })
    }

    setDiliUrl(value: string) {
        this.state.dili.appUrl = value;
        this.setState({
            dili: this.state.dili
        })
    }

    setJxMchNo(value: string) {
        this.state.jx.mchNo = value;
        this.setState({
            jx: this.state.jx
        })
    }

    setJxPrivate(value: string) {
        this.state.jx.appPrivate = value;
        this.setState({
            jx: this.state.jx
        })
    }

    setJxPublic(value: string) {
        this.state.jx.jxPublic = value;
        this.setState({
            jx: this.state.jx
        })
    }

    setJxUrl(value: string) {
        this.state.jx.appUrl = value;
        this.setState({
            jx: this.state.jx
        })
    }

    handleChangePass() {
        if (!this.state.type) {
            message.error("类型不能为空");
            return;
        }

        let cfg: any = {
            name: this.state.name,
            type: this.state.type,
            trade: this.state.trade,
            fee: this.state.fee,
            enabled: true,
            limit: this.state.limit
        }

        if (this.state.type == 'alipay') {
            cfg.appId = this.state.ali.appId;
            cfg.appPrivate = this.state.ali.appPrivate;
            cfg.aliPublic = this.state.ali.aliPublic;
            cfg.certPath = this.state.ali.certPath;

        } else if (this.state.type == 'dili') {
            cfg.mchNo = this.state.dili.mchNo;
            cfg.appId = this.state.dili.appId;
            cfg.appKey = this.state.dili.appKey;
            cfg.appUrl = this.state.dili.appUrl;
        } else if (this.state.type == 'jx') {
            cfg.mchNo = this.state.jx.mchNo;
            cfg.appPrivate = this.state.jx.appPrivate;
            cfg.jxPublic = this.state.jx.jxPublic;
            cfg.appUrl = this.state.jx.appUrl;
        }

        let cfgStr = JSON.stringify(cfg)
        let tm = Math.floor(new Date().valueOf() / 1000)
        NetUtil.Post(NetUtil.BaseUrl + "channel/add",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(cfgStr + tm + this.state.type + StorageValue.userName + StorageValue.Token),
                type: this.state.type,
                cfg: cfgStr
            },
            (msg: any) => {
                let result = msg.result
                if (result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.error("添加通道成功");
                this.resetState()
                this.props.onSendApplySuccess();
            },
            (err: any) => {
                message.error(err);
                return;
            })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return (
            <Modal
                centered={true}
                visible={this.props.visible}
                title="添加通道"
                okText="添加"
                cancelText='取消'
                onCancel={() => { this.props.onCancel() }}
                onOk={() => {
                    this.handleChangePass()
                }}
            >
                <Tabs defaultActiveKey="1" onChange={(e: any) => this.setType(e)}>
                    <Tabs.TabPane tab="支付宝" key="alipay">
                        <Form style={{ textAlign: "center" }}>
                            <Form.Item label='通道名:'>
                                <Input
                                    placeholder="通道名..."
                                    // style={{ width: '50%' }}
                                    prefix={<UserOutlined />}
                                    value={this.state.name}
                                    onChange={(e) => this.setName(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>

                            <Form.Item label='支付类型:' {...formItemStle}>
                                <Select
                                    defaultValue={EnumUtil.ChannelTradeList()[0].value}
                                    size={"large"}
                                    onSelect={(key: any) => { this.setTrade(key) }}
                                >
                                    {
                                        EnumUtil.ChannelTradeList().map((item: any) => {
                                            return (
                                                <Option key={item.key} value={item.key}>{item.value}</Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>

                            <Form.Item label='手续费:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="手续费..."
                                    prefix={<EyeOutlined />}
                                    value={this.state.fee}
                                    onChange={(e) => this.setFee(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='每日限额:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="-1为不限制，0为手动禁用通道，其他不每日限额"
                                    prefix={<EyeOutlined />}
                                    value={this.state.limit}
                                    onChange={(e) => this.setLimit(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='应用ID:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="应用ID"
                                    prefix={<EyeOutlined />}
                                    value={this.state.ali.appId}
                                    onChange={(e) => this.setAppId(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='应用私钥匙:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="应用私钥匙"
                                    prefix={<EyeOutlined />}
                                    value={this.state.ali.appPrivate}
                                    onChange={(e) => this.setAliPrivate(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='支付宝公钥:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="支付宝公钥"
                                    prefix={<EyeOutlined />}
                                    value={this.state.ali.aliPublic}
                                    onChange={(e) => this.setAliPublic(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='证书路径:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="证书路径,证书为空则使用公钥模式"
                                    prefix={<EyeOutlined />}
                                    value={this.state.ali.certPath}
                                    onChange={(e) => this.setAliCertPath(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="滴沥付" key="dili">
                        <Form style={{ textAlign: "center" }}>
                            <Form.Item label='通道名:'>
                                <Input
                                    placeholder="通道名..."
                                    // style={{ width: '50%' }}
                                    prefix={<UserOutlined />}
                                    value={this.state.name}
                                    onChange={(e) => this.setName(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>

                            <Form.Item label='支付类型:' {...formItemStle}>
                                <Select
                                    defaultValue={EnumUtil.ChannelTradeList()[0].value}
                                    size={"large"}
                                    onSelect={(key: any) => { this.setTrade(key) }}
                                >
                                    {
                                        EnumUtil.ChannelTradeList().map((item: any) => {
                                            return (
                                                <Option key={item.key} value={item.key}>{item.value}</Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>

                            <Form.Item label='手续费:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="手续费..."
                                    prefix={<EyeOutlined />}
                                    value={this.state.fee}
                                    onChange={(e) => this.setFee(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='每日限额:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="-1为不限制，0为手动禁用通道，其他不每日限额"
                                    prefix={<EyeOutlined />}
                                    value={this.state.limit}
                                    onChange={(e) => this.setLimit(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='请求地址:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="请求地址"
                                    prefix={<EyeOutlined />}
                                    value={this.state.dili.appUrl}
                                    onChange={(e) => this.setDiliUrl(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='商户号:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="商户号"
                                    prefix={<EyeOutlined />}
                                    value={this.state.dili.mchNo}
                                    onChange={(e) => this.setDiliMchNo(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='应用ID:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="应用ID"
                                    prefix={<EyeOutlined />}
                                    value={this.state.dili.appId}
                                    onChange={(e) => this.setDiliAppId(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='私钥:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="私钥:"
                                    prefix={<EyeOutlined />}
                                    value={this.state.dili.appKey}
                                    onChange={(e) => this.setDiliKey(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="精秀付" key="jx">
                        <Form style={{ textAlign: "center" }}>
                            <Form.Item label='通道名:'>
                                <Input
                                    placeholder="通道名..."
                                    // style={{ width: '50%' }}
                                    prefix={<UserOutlined />}
                                    value={this.state.name}
                                    onChange={(e) => this.setName(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>

                            <Form.Item label='支付类型:' {...formItemStle}>
                                <Select
                                    defaultValue={EnumUtil.ChannelTradeList()[0].value}
                                    size={"large"}
                                    onSelect={(key: any) => { this.setTrade(key) }}
                                >
                                    {
                                        EnumUtil.ChannelTradeList().map((item: any) => {
                                            return (
                                                <Option key={item.key} value={item.key}>{item.value}</Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>

                            <Form.Item label='手续费:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="手续费..."
                                    prefix={<EyeOutlined />}
                                    value={this.state.fee}
                                    onChange={(e) => this.setFee(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='每日限额:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="-1为不限制，0为手动禁用通道，其他不每日限额"
                                    prefix={<EyeOutlined />}
                                    value={this.state.limit}
                                    onChange={(e) => this.setLimit(Number(e.target.value))}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='请求地址:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="请求地址"
                                    prefix={<EyeOutlined />}
                                    value={this.state.jx.appUrl}
                                    onChange={(e) => this.setJxUrl(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='商户号:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="商户号"
                                    prefix={<EyeOutlined />}
                                    value={this.state.jx.mchNo}
                                    onChange={(e) => this.setJxMchNo(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='私钥:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="私钥"
                                    prefix={<EyeOutlined />}
                                    value={this.state.jx.appPrivate}
                                    onChange={(e) => this.setJxPrivate(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item label='平台公钥:'>
                                <Input
                                    // style={{ width: '50%' }}
                                    placeholder="平台公钥:"
                                    prefix={<EyeOutlined />}
                                    value={this.state.jx.jxPublic}
                                    onChange={(e) => this.setJxPublic(e.target.value)}
                                    size="large"
                                />
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                </Tabs>


            </Modal>
        );
    }
}