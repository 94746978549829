import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from '../../Utils/NetUtil';
import { message, Typography, Descriptions, Divider, Button } from 'antd';
import { Form } from '@ant-design/compatible';
import { PlusOutlined } from '@ant-design/icons';
import md5 from "md5"
const { Title } = Typography;

interface IProps {

}

interface IState {
}

export default class InfoView extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
    }

    private reload() {
        let tm = Math.floor(new Date().valueOf() / 1000)
        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token),
        };

        NetUtil.Post(NetUtil.BaseUrl + "reload", postData,
            (msg: any) => {
                message.error(msg.msg);
                return;
            },
            (err: any) => {
                throw (err)
            })
    }

    render() {
        return (
            <div>
                <Form layout='inline'>
                    <Form.Item >
                        <Button
                            icon={<PlusOutlined />}
                            type="primary"
                            onClick={() => { this.reload() }}
                        >
                            重新加载配置
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />
                <Title level={4}>当前用户信息：</Title>
                < Descriptions bordered column={1} size='small'>
                    <Descriptions.Item label='账号:'>
                        {StorageValue.userName}
                    </Descriptions.Item>
                    <Descriptions.Item label='显示昵称:'>
                        {StorageValue.nickName}
                    </Descriptions.Item>
                </Descriptions>
            </div >
        );
    }
}