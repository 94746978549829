import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Divider, Table, Input, Button, DatePicker, Select, Modal } from 'antd';
import { subDays } from 'date-fns'
import md5 from "md5"
import moment from 'moment';
import TimeUtil from '../../Utils/TimeUtil';
import NetUtil from '../../Utils/NetUtil';
import StorageValue from '../../Utils/StorageValue';
import EnumUtil from '../../Utils/EnumUtil';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

interface IProps {

}

interface IState {
    LogsList: Array<any>
    begin: any
    end: any
    user: string
    channel: string
    state: string,
}

/**
 * 兑换日志
 *Created by liangzai on 21/04/07.
 */
export default class RechargeLogView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    private nowTime: number = TimeUtil.GetDayEnd(new Date());
    private yesterday = TimeUtil.GetDayBegin(subDays(this.nowTime, 0));
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            begin: this.yesterday,
            end: this.nowTime,
            user: '',
            channel: '',
            state: '-1',
        }
    }

    //刷新界面
    private NetRefreshPanel() {
        let tm = Math.floor(new Date().valueOf() / 1000)
        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5("" + Math.floor(this.state.begin / 1000) + this.state.channel + Math.floor(this.state.end / 1000) + this.state.user + this.state.state + tm + StorageValue.userName + StorageValue.Token),
            begin: Math.floor(this.state.begin / 1000),
            end: Math.floor(this.state.end / 1000),
            game: this.state.user,
            channel: this.state.channel,
            status: Number(this.state.state)
        };

        NetUtil.Post(NetUtil.BaseUrl + "log/list", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];

                if (msg.list) {
                    for (let index = 0; index < msg.list.length; index++) {
                        const element = msg.list[index];
                        data.push(element)
                    }
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.id < b.id ? 1 : -1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }
    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }
    //查询按钮
    private OnSelectClick() {
        this.NetRefreshPanel()
    }
    //开始和结束时间
    private OnTimeChange(date: any) {
        if (date.length < 1) {
            this.setState({
                begin: this.yesterday,
                end: this.nowTime,
            })
            return;
        }
        let begindata = moment(date[0]).valueOf();
        let enddata = moment(date[1]).valueOf();

        this.setState({
            begin: begindata,
            end: enddata
        })
    }

    //推送
    private Resend(id: string) {
        let tm = Math.floor(new Date().valueOf() / 1000)
        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(id + tm + StorageValue.userName + StorageValue.Token),
            id: id
        };

        NetUtil.Post(NetUtil.BaseUrl + "log/resend", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.info("重新推送成功");
                return;
            },
            (err: any) => {
                throw (err)
            })
    }

    //设置状态
    private SetState(value: string) {
        this.setState({
            state: value
        })
    }

    //设置游戏类型
    private SetGame(value: string) {
        this.setState({
            user: value
        })
    }

    //设置通道
    private SetChannel(value: string) {
        this.setState({
            channel: value
        })
    }


    render() {

        this.columns = [
            { align: "center", title: "订单ID", dataIndex: "id", key: "id" },
            {
                align: "center", title: "游戏", dataIndex: "user", key: "user", render: (text, record) => {
                    return <div>{StorageValue.getUserName(record.user)}</div>
                }
            },
            { align: "center", title: "玩家ID", dataIndex: "playerId", key: "playerId" },
            { align: "center", title: "金额", dataIndex: "amount", key: "amount" },
            { align: "center", title: "商品", dataIndex: "subject", key: "subject" },
            {
                align: "center", title: "创建时间", dataIndex: "ct", key: "ct", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text * 1000, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            {
                align: "center", title: "完成时间", dataIndex: "ft", key: "ft", render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text * 1000, 'yyyy-MM-dd hh:mm:ss')}</div>
                }
            },
            {
                align: "center", title: "充值渠道", dataIndex: "channel", key: "channel", render: (text, record) => {
                    return <div>{StorageValue.getChannelName(record.channel)}</div>
                }
            },
            { align: "center", title: "消息", dataIndex: "msg", key: "msg" },
            {
                align: "center", title: "状态", dataIndex: "status", key: "status", render: (text, record) => {
                    return <div>{EnumUtil.RechargeStatus.get(record.status)}</div>
                }
            },
            {
                align: "center", title: "操作", dataIndex: "operation", key: "operation", render: (text, record) => {
                    if (record.status == 1) {
                        return (<div>
                            <a href="javascript:;" onClick={() => this.Resend(record.id)} >推送</a>
                        </div>)
                    } else {
                        return (<div></div>)
                    }

                }
            }
        ]

        return (
            <div>
                <Form layout='inline'>

                    <Form.Item label='日期选择' >
                        <RangePicker
                            defaultValue={[moment(TimeUtil.GetDayBegin(subDays(Date.now(), 0))), moment(TimeUtil.GetDayEnd(new Date()))]}
                            format='YYYY-MM-DD'
                            onChange={(e: any) => { this.OnTimeChange(e) }}
                        />
                    </Form.Item>
                    <Form.Item label='游戏'>
                        <Select
                            style={{ width: 120 }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetGame(value) }}
                        >
                            <Option key={""} value={""}>{"全部"}</Option>
                            {
                                StorageValue.User.map((item: any) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='渠道'>
                        <Select
                            style={{ width: 120 }}
                            defaultValue={""}
                            onChange={(value: string) => { this.SetChannel(value) }}
                        >
                            <Option key={""} value={""}>{"全部"}</Option>
                            {
                                StorageValue.Channel.map((item: any) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>

                    <Form.Item label='状态'>
                        <Select
                            style={{ width: 120 }}
                            defaultValue={"-1"}
                            onChange={(value: string) => { this.SetState(value) }}
                        >
                            {
                                EnumUtil.RechargeStatusList().map((item: any) => {
                                    return (
                                        <Option key={item.key} value={item.key}>{item.value}</Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>

                </Form>
                <Divider />
                <Table
                    pagination={{ pageSize: 100 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />
            </div>
        );
    }
} 