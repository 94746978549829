import { isNullOrUndefined } from "util";

export default class StorageValue {

    private static _userNmae: string = '';
    public static get userName(): string {
        if (this._userNmae == '')
            this._userNmae = LocalStorage.get('userName')
        return this._userNmae;
    }
    public static set userName(value) {
        this._userNmae = value
        LocalStorage.put('userName', this._userNmae)
    }

    private static _nickNmae: string = '';
    public static get nickName(): string {
        if (this._nickNmae == '')
            this._nickNmae = LocalStorage.get('nickName')
        return this._nickNmae;
    }
    public static set nickName(value) {
        this._nickNmae = value
        LocalStorage.put('nickName', this._nickNmae)
    }

    private static _passWord: string = '';
    public static get passWord() {
        if (this._passWord == '')
            this._passWord = LocalStorage.get('passWord')
        return this._passWord;
    }
    public static set passWord(value) {
        this._passWord = value
        LocalStorage.put('passWord', this._passWord)
    }

    private static _isRemberPass: string = '';
    public static get isRemberPass() {
        if (this._isRemberPass == '')
            this._isRemberPass = LocalStorage.get('isRemberPass')
        return this._isRemberPass;
    }
    public static set isRemberPass(value) {
        this._isRemberPass = value
        LocalStorage.put('isRemberPass', this._isRemberPass)
    }

    private static _token: string = '';
    public static get Token(): string {
        if (this._token == '')
            this._token = LocalStorage.get('token')
        return this._token;
    }
    public static set Token(value) {
        this._token = value
        LocalStorage.put('token', this._token)
    }

    private static _type: number = 0;
    public static get Type(): number {
        if (this._type == 0)
            this._type = LocalStorage.get('type')
        return this._type;
    }
    public static set Type(value) {
        this._type = value
        LocalStorage.put('type', this._type)
    }

    private static _channel: Array<any>
    private static _cm: Map<string, string>
    public static get Channel(): Array<any> {
        if (!this._channel) {
            let c = LocalStorage.get('channel');
            this._cm = new Map<string,string>()

            if(c){
                this._channel = JSON.parse(c);
                if (this._channel) {
                    this._channel.map((item: any) => {
                        this._cm.set(item.key, item.value);
                    })
                }
            }
        }

        return this._channel;
    }
    public static set Channel(channel: Array<any>) {
        this._channel = channel;
        this._cm = new Map<string,string>()
        if (this._channel) {
            this._channel.map((item: any) => {
                this._cm.set(item.key, item.value);
            })
        }

        LocalStorage.put("channel", JSON.stringify(this._channel))
    }
    public static getChannelName(id: string): string {
        if(!this._cm){
            if(!this.Channel){
                return "无"
            }
        }
        let item = this._cm.get(id)
        if (item) {
            return item;
        }

        return "无"
    }

    private static _user: Array<any>
    private static _um: Map<string, string>
    public static get User(): Array<any> {
        if (!this._user) {
            let c = LocalStorage.get('user');
            this._um = new Map<string,string>()
            if(c){
                this._user = JSON.parse(c);
                if (this._user) {
                    this._user.map((item: any) => {
                        this._um.set(item.key, item.value);
                    })
                }
            }
        }

        return this._user;
    }
    public static set User(user: Array<any>) {
        this._user = user;
        this._um = new Map<string,string>()
        if (this._user) {
            this._user.map((item: any) => {
                this._um.set(item.key, item.value);
            })
        }

        LocalStorage.put("user", JSON.stringify(this._user))
    }
    public static getUserName(id: string): string {
        if(!this._um){
            if(!this.User){
                return ""
            }
        }

        let item = this._um.get(id)
        if (item) {
            return item;
        }

        return ""
    }
}


class LocalStorage {
    public static put(key: string, data: any) {
        if (window.localStorage == undefined) {
            return;
        }

        try {
            window.localStorage[key] = data;
        }
        catch (err) {
            console.error("LocalStorage put error,key:" + key)
        }
    };

    public static get(key: string) {
        if (window.localStorage == undefined) {
            return undefined;
        }
        try {
            return window.localStorage[key];
        }
        catch (err) {
            console.error("LocalStorage get error,key:" + key)
            return undefined;
        }
    };

    public static remove(key: string) {
        if (window.localStorage == undefined) {
            return;
        }
        try {
            window.localStorage.removeItem(key);
        }
        catch (err) {
            console.error("LocalStorage Remove error,key:" + key)
        }
    };

    public static clear() {
        if (window.localStorage == undefined) {
            return;
        }
        window.localStorage.clear();
    };
}