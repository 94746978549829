import NetUtil from "../Utils/NetUtil";
import UserInfo from "../UserInfo/UserInfo";
import StorageValue from "../Utils/StorageValue";

export default class LoginHandle {

    public static OnError: Function;
    public static history: any;


    public static LoginStep1() {
        NetUtil.Post(NetUtil.BaseUrl + "salt",
            {
                user: UserInfo.Account
            },
            (msg: any) => {
                let result = msg.result
                if (result != 0) {
                    if (this.OnError != null) {
                        this.OnError(msg.msg)
                    }
                    return
                }
                UserInfo.Salt = msg.salt;
                this.LoginStep2()
            },
            () => {

            })
    }

    public static LoginStep2() {
        NetUtil.Post(NetUtil.BaseUrl + "login",
            {
                user: UserInfo.Account,
                pwd: UserInfo.md5Password,
            },
            (msg: any) => {
                let result = msg.result
                if (result != 0) {
                    if (this.OnError != null) {
                        this.OnError(msg.msg)
                    }
                    return
                }
                StorageValue.Token = msg.token;
                StorageValue.Type = msg.type;
                StorageValue.nickName = msg.nickName;
                this.history.push('/home/channellist');
            },
            () => {

            })
    }
}