export default class EnumUtil {
    public static ChannelTypes = new Map([
        ["alipay", "支付宝"],
        ["dili", "滴沥付"],
        ["jx", "精秀付"],
    ]);

    public static ChannelTrade = new Map([
        ["alipayApp", "支付宝App"],
        ["alipayH5", "支付宝H5"]
    ]);

    public static UserTypes = new Map([
        [1, "管理员"],
        [2, "管理员"],
        [3, "使用者"]
    ]);

    public static RechargeStatus = new Map([
        [-1, "全部"],
        [0, "等待"],
        [1, "成功"],
        [2, "失败"],
        [3, "完成"],
    ]);

    private static _channelTrade: Array<{ key: string, value: string }>
    public static ChannelTradeList(): Array<{ key: string, value: string }> {
        if (!this._channelTrade) {
            this._channelTrade = new Array<{ key: string, value: string }>()
            EnumUtil.ChannelTrade.forEach((v: string, k: string) => {
                this._channelTrade.push({ key: k, value: v })
            })
        }


        return this._channelTrade
    }

    private static _rechargeStatus: Array<{ key: string, value: string }>
    public static RechargeStatusList(): Array<{ key: string, value: string }> {
        if (!this._rechargeStatus) {
            this._rechargeStatus = new Array<{ key: string, value: string }>()
            EnumUtil.RechargeStatus.forEach((v: string, k: number) => {
                this._rechargeStatus.push({ key: k.toString(), value: v })
            })
        }


        return this._rechargeStatus
    }
}