import React from 'react';
import { Route } from 'react-router-dom';
import UserSettingView from '../../components/UserSetting/UserSettingView';
import SliderBarView from '../../components/SliderBar/SliderBarView';
import styles from './Home.module.css';
import InfoView from '../Info/InfoView';
import ChangePassView from '../Account/ChangePassView';
import UserListView from '../Account/UserListView';
import ChannelListView from '../Channel/ChannelListView';
import RechargeLogView from '../Log/RechargeLogView';
import RechargeTotalView from '../Log/RechargeTotalView';

export default class Home extends React.Component {
    constructor(props) {
        super();
        this.state = {
            collapsed: false,
        }
    }

    get sidebarWidth() {
        return this.state.collapsed ? 80 : 256
    }

    get sidebarStyle() {
        return {
            flex: '0 0 ' + this.sidebarWidth + 'px',
            width: this.sidebarWidth + 'px'
        }
    }

    setCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        return (
            <div className="ant-layout ant-layout-has-sider">
                <div style={this.sidebarStyle} className="ant-layout-sider ant-layout-sider-dark">
                    <SliderBarView collapsed={this.state.collapsed} />
                </div>
                <div className={`${styles['content-wrapper']} ant-layout`} style={{ overflow: "hidden" }}>
                    <div className={`${styles.header} ant-layout-header`}>
                        <UserSettingView collapsed={this.state.collapsed} setCollapsed={() => this.setCollapsed()} />
                    </div>
                    <div className={`${styles.content} ant-layout-content`}>
                        <Route path="/home/info" component={InfoView} />
                        <Route path="/home/changepwd" component={ChangePassView} />
                        <Route path="/home/userlist" component={UserListView} />
                        <Route path="/home/channellist" component={ChannelListView} />
                        <Route path="/home/loglist" component={RechargeLogView} />
                        <Route path="/home/logtotal" component={RechargeTotalView} />
                    </div>
                </div>
            </div>
        );
    }
}