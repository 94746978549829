import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from "../../Utils/NetUtil";
import { Form } from '@ant-design/compatible';
import { ColumnProps } from 'antd/lib/table';
import { PlusOutlined } from '@ant-design/icons';
import md5 from "md5"
import { message, Typography, Divider, Table, Button } from 'antd';
import CommDialog from '../../Utils/CommDialog';
import EnumUtil from '../../Utils/EnumUtil';
import AddUserModal from './AddUserModal';
import ChangeUserModal from './ChangeUserModal'
const { Title } = Typography;

interface IProps {

}

interface IState {
    addUserFormVisible: boolean,
    channelUserFormData: any,
    LogsList: Array<any>
}


export default class UserListView extends React.Component<IProps, IState> {
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            addUserFormVisible: false,
            channelUserFormData: null,
            LogsList: [],
        }
    }

    //render渲染之前执行
    componentWillMount() {
        this.NetRefreshPanel();
    }

    //取消
    private handleCancel() {
        this.setState({
            addUserFormVisible: false,
            channelUserFormData: null,
        })
    }

    //处理弹出框提交完成关闭弹框  操作
    private handleCreate() {
        this.handleCancel();
        this.NetRefreshPanel();
    }

    private Change(record: any) {
        this.setState({
            channelUserFormData: record,
        })
    }

    NetRefreshPanel() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token),
        };

        NetUtil.Post(NetUtil.BaseUrl + "user/list", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];
                let users = new Array<{ key: number, value: string }>()

                if (msg.list) {
                    for (let index = 0; index < msg.list.length; index++) {
                        const element = msg.list[index];

                        if (element.channel && element.channel.length > 0) {
                            let channel = ""
                            for (let i = 0; i < element.channel.length - 1; i++) {
                                let c = StorageValue.getChannelName(element.channel[i]);
                                if (c) {
                                    channel += c + ","
                                }
                            }
                            channel += StorageValue.getChannelName(element.channel[element.channel.length - 1])
                            element.channel = channel;
                        } else {
                            element.channel = "全部";
                        }

                        data.push(element)

                        if (element.type == 3) {
                            users.push({ key: element.user, value: element.nickname })
                        }
                    }


                    StorageValue.User = users.sort(
                        (a: any, b: any) => {
                            return a.value > b.value ? 1 : -1
                        })
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            if (a.type != b.type) {
                                return a.type > b.type ? 1 : -1
                            } else {
                                return a.nickname > b.nickname ? 1 : -1
                            }
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    private Disabled(record: any) {
        let text = record.disabled ? '解禁' : '禁用'
        CommDialog.showConfirm(text + "确认", "是否" + text + ' [' + record.id + ']', () => {
            let tm = Math.floor(new Date().valueOf() / 1000)
            let postData = {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(record.user + (!record.disabled) + tm + StorageValue.userName + StorageValue.Token),
                disabled: !record.disabled,
                change: record.user
            };

            NetUtil.Post(NetUtil.BaseUrl + "user/disabled", postData,
                (msg: any) => {
                    let Result = msg.result
                    if (Result != 0) {
                        message.error(msg.msg);
                        return;
                    }

                    message.info(text + "成功！");
                    this.NetRefreshPanel();
                },
                (err: any) => {
                    throw (err)
                })
        });
    }

    private Delete(record: any) {
        CommDialog.showConfirm("删除确认", "是否删除" + ' [' + record.id + ']', () => {
            let tm = Math.floor(new Date().valueOf() / 1000)
            let postData = {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(record.user + tm + StorageValue.userName + StorageValue.Token),
                change: record.user
            };

            NetUtil.Post(NetUtil.BaseUrl + "user/del", postData,
                (msg: any) => {
                    let Result = msg.result
                    if (Result != 0) {
                        message.error(msg.msg);
                        return;
                    }

                    message.info("删除成功！");
                    this.NetRefreshPanel();
                },
                (err: any) => {
                    throw (err)
                })
        });
    }

    render() {
        this.columns = [
            { align: "center", title: "用户", dataIndex: "user", key: "user", width: "100px", fixed: 'left' },
            { align: "center", title: "昵称", dataIndex: "nickname", key: "nickname" },
            {
                align: "center", title: "用户类型", dataIndex: "type", key: "type", render: (text, record) => {
                    return <div>{EnumUtil.UserTypes.get(text)}</div>
                }
            },
            {
                align: "center", title: "当前通道", dataIndex: "cur", key: "cur", render: (text, record) => {
                    return <div>{StorageValue.getChannelName(record.cur)}</div>
                }
            },
            { align: "center", title: "可用通道", dataIndex: "channel", key: "channel" },
            { align: "center", title: "IP白名单", dataIndex: "ips", key: "ips" },
            {
                align: "center", title: "操作", dataIndex: "operation", key: "operation", render: (text, record) => {
                    if (record.user == "admin") {
                        return (<div></div>)
                    } else if (record.disabled) {
                        return (<div>
                            <a href="javascript:;" onClick={() => this.Change(record)} >修改</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Disabled(record)} >解禁</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                        </div>)
                    } else {
                        return (<div>
                            <a href="javascript:;" onClick={() => this.Change(record)} >修改</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Disabled(record)} >禁用</a>
                            <Divider type='vertical' />
                            <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                        </div>)
                    }

                }
            },
        ]

        return (
            <div>
                <Form layout='inline'>
                    <Form.Item >
                        <Button
                            icon={<PlusOutlined />}
                            type="primary"
                            onClick={() => { this.setState({ addUserFormVisible: true }) }}
                        >
                            添加用户
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />
                {/* //添加账号 */}
                <AddUserModal
                    visible={this.state.addUserFormVisible}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                {/* //修改通道 */}
                <ChangeUserModal
                    data={this.state.channelUserFormData}
                    visible={this.state.channelUserFormData != null}
                    onCancel={() => { this.handleCancel() }}
                    onSendApplySuccess={() => { this.handleCreate() }}
                />
                <Table
                    pagination={{ pageSize: 100 }}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />

            </div >
        );
    }
}