import md5 from  "md5"

export default class UserInfo{
    public static Account:string;       //账号
    public static Password:string;      //密码
    public static Token:string; 
    public static Salt:string;

    public static get md5Password():string{
        return md5(this.Password + this.Salt)
    }
    public static Level:string          //权限等级
}